import { showNotification } from '@mantine/notifications';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';

export const handleLoginError = (error: any) => {
  switch (error.code) {
    case 'auth/user-not-found':
      toast.error('User not found');
      break;
    case 'auth/invalid-email':
      toast.error('Invalid email');
      showNotification({
        color: 'red',
        icon: <X />,
        message: 'Invalid email',
      });
      break;
    case 'auth/too-many-requests':
      toast.error('Too many attempts! 🤥');
      showNotification({
        color: 'red',
        icon: <X />,
        message: 'Too many attempts! 🤥',
      });
      break;
    case 'auth/wrong-password':
      toast.error("Password doesn't match 🤥");
      break;
    default:
      toast.error('Something went wrong');
      break;
  }
};

export const handleLoginSuccess = (createdUser) => {};
